import {Component, OnInit} from '@angular/core';
import {LayoutService} from "@app/core/services/layout.service";

@Component({
  selector: 'sa-collapse-menu',
  templateUrl: './collapse-menu.component.html',
  styleUrls:['./collapse-menu.component.css']
})
export class CollapseMenuComponent {

  constructor(
    private layoutService: LayoutService
  ) {

  }

  onToggle() {
    this.layoutService.onCollapseMenu()
  }
  menuMinify(){
    this.layoutService.onMinifyMenu() 
  }
}
