import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  apiUrl:string;
  accounts_api_url:string;
  admin_api_url:string;
  constructor(private httpClient: HttpClient) {
    // this.accounts_api_url = environment.API_END_POINT_ACCOUNT[localStorage.getEncryptedItem('domain')];
    // this.admin_api_url = environment.API_END_POINT_ADMIN[localStorage.getEncryptedItem('domain')]
    this.admin_api_url = localStorage.getEncryptedItem('API_END_POINT_ADMIN');
    this.accounts_api_url = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
   }
   getUserImg(accID){
    return this.httpClient.post(this.accounts_api_url+`users/fetch-image`,accID)
  }

  checkBillingDetails(accID){
    return this.httpClient.post(this.admin_api_url+`admin/checkBillingDetails`,accID)
  }
  totalColumns(companyId ){
    return this.httpClient.post(this.admin_api_url+`admin/totalColumns`,companyId )

  }
  getCompanyDetails(encrData){
    return this.httpClient.post(this.admin_api_url +`admin/company-details`,encrData).pipe(map((response: Response) => response));
  }
  getCompanyImg(data){
    return this.httpClient.post(this.admin_api_url + 'admin/getCompanyLogo', data).pipe(map((response: Response) => response)); 
  }
}
