import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmitService } from '@app/ts/services/emit.service';

@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    // private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    private currentHash;
    constructor(private http: HttpClient, private emitService: EmitService) {}

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
     public initVersionCheck(url, frequency = 1000 * 30 * 1) {
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
        this.currentHash = localStorage.getItem('hash') ? localStorage.getItem('hash') : '';
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .subscribe(
                (response: any) => {
                    
                    const hash = response.hash;
                    if(this.currentHash=='')
                    this.currentHash = hash
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);
                    // If new version, do something
                    if (hashChanged) {
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        // for an example: location.reload();
                       // localStorage.setItem('hash',hash)
                       console.log('dist changed'); 
                       this.emitService.newDistSubject.next(true);
                    //    window.history.forward(1);
                    
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    // this.currentHash = hash;
                    localStorage.setItem('hash',hash)
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        if (currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }
        return currentHash !== newHash || !currentHash;
    }
}