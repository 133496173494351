import { Validators } from '@angular/forms';
import { ConfigOption } from '@ngx-formly/core';


// export function requiredMessage(err, field) {
//     let translated = getTranslation("common_all_form_validation_message_required");
//     return `${field.templateOptions.label} ` + translated;
// }

export function requiredMessage(){
    let translated = getTranslation("validation_message_required");
    return translated;
}
export function invalidEmailAddress() {
    return getTranslation("validation_message_invalid_email");
}

export function duplicateValidationMessage() {
    return getTranslation("register_duplicate_company_error");
}

export function pendingValidationMessage(){
    return getTranslation("register_pending_company_error");
}

export function getTranslation(phrase) {
    let langData = localStorage.getEncryptedItem('lang_data');
    let retVal = "";
    if (langData) {
        let parsedData = JSON.parse(langData);
        retVal = parsedData && parsedData[phrase] ? parsedData[phrase] : phrase;
        return retVal;
    }
    return phrase;
}

export const NgFormlyConfig: ConfigOption = {
    validators: [
        { name: 'required', validation: Validators.required }
    ],
    validationMessages: [
        { name: 'required', message: requiredMessage },
        { name: 'invalidEmailAddress', message: invalidEmailAddress },
        { name: 'duplicateValidationMessage', message: duplicateValidationMessage },
        { name: 'pendingValidationMessage', message: pendingValidationMessage },
    ],

};