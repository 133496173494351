import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { CommonService } from '@app/core/services/common.service';
import { UserService, LayoutService } from './core/services';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { I18nService } from './shared/i18n/i18n.service';
import { EmitService } from './ts/services/emit.service';
import { environment } from '@env/environment';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";
import { Color, Solver } from "@app/shared/utils/filterGenerator";
declare var $:any;
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet> <div class="cookie-consent hide-consent show-consent" *ngIf="showCookieConsent"><span class="cookie-text">{{'common_login_cookie_content' |i18n}} <a class="cookie_anchor" [href]="accountsUrl+'privacy-policy'" target="_blank">{{'common_all_privacy_policy' | i18n}} </a></span><button class="consent-btn mt-4" (click)="hideCookieConsent()"> {{'footer_cookie_gotIt' | i18n}}</button></div>`,
})
export class AppComponent implements OnInit{
  countryList:any = [];
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  action = '';
  showCookieConsent:boolean;
  adminUrl = "";
  constructor(private appService : AppService,private router: Router,private commonService:CommonService,private userService: UserService,private idle: Idle,private layoutService:LayoutService,private emitService:EmitService,private versionCheckService:VersionCheckService){
    this.appService.setLocalStorageFunctions();
    // this.getCountryList();
    // this.setPermision();
    setInterval(() => {
      this.appService.createAccessToken().subscribe(data=>{ }); 
    },600000);
    // let cookieExist = '{"cookieCreatedTime":"2022-06-21T07:07:13.000Z","userMail":"r.murali@iomarket.in","partyID":"171","userID":50,"accId":50,"netcomId":"41420-0000411494-85"}'

    let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));
    // let cookieExist = this.commonService.decrypt(decodeURIComponent('r629QRYi0NfhX8n25sJEipLxfd8WqEBqzPeIiC38Ghk7TpPQTGYtQNmXQ%2FmyapOsDeA3OzirbI%2BAyCHhQYA7VqKrVEPDsDwZ8awZWUaM%2FrjmIflMtTz9wC%2FNJ%2BTXP9%2Bo1LPwR2sIumSOzBqd3257l2ny0T%2Bac1BamDXPt5EKSH2h%2F%2FofrJHoKS9jP61AzqDo%2FjSPvT5ks2diXTonh7UDWIGiEthg6UQ8ZTP184kMNvJ2cqqvFXH7EyoHpaOcAQZK'));
    if (cookieExist) {
      // this.setPermision();
      cookieExist =  JSON.parse(cookieExist);
      localStorage.getEncryptedItem('partyID') != cookieExist['partyID'] || localStorage.getEncryptedItem('userID') != cookieExist['userID'] ? localStorage.removeEncryptedItem('userData') : '';
      localStorage.setEncryptedItem('userMail',cookieExist['userMail']);
      localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
      localStorage.setEncryptedItem('userID',cookieExist['userID']);
      localStorage.setEncryptedItem('accId',cookieExist['accId']);
      localStorage.setEncryptedItem('netcomId',cookieExist['netcomId']);
      localStorage.setEncryptedItem('isBexioCompany',cookieExist.hasOwnProperty('isBexioCompany')?cookieExist['isBexioCompany']:'false');
    }
    // const baseUrl = 'http://testadmin.';
    
    const host = window.location.host
    const parts = host.split(".");
    parts.shift(); 
    const domain = parts.join(".");
    console.log(domain,'domaindomaindomaindomain');
    localStorage.setEncryptedItem('domain',domain);

    // let localurl = baseUrl + domain + '/#/';
    // this.adminUrl = localurl;

    // let API_END_POINT_ACCOUNTS = 'http://testrahul.' + domain + ':4000/api/v1/';
    // let API_END_POINT_ADMIN = 'http://testrahul.' + domain + ':4500/api/v1/';
    // localStorage.setEncryptedItem('accountsUrl',localurl);
    let API_END_POINT_ACCOUNTS = 'https://fjy29.api.' + domain + '/api/v1/';
    localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS',API_END_POINT_ACCOUNTS);
    // localStorage.setEncryptedItem('API_END_POINT_ADMIN',API_END_POINT_ADMIN);
    

  
    this.getWhiteLabelConfig(domain);

    // let p = String(["admin.companyProfile.*","admin.user.*","admin.billing.*","admin.subscription.*","accounts.profile.*"]);
    // let a = this.commonService.encrypt(p);
    // localStorage.setEncryptedItem('permissions',a); 
    // set idle parameters
    this.idle.setIdle(1800); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      // cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT";
      let currentURL = localStorage.getEncryptedItem('ADMIN_URL')+'companyDetails';
    
      let lastActive;
      lastActive = Date.parse(this.commonService.decrypt(this.commonService.getCookie(this.commonService.encrypt(environment.USER_ACTIVE_CHECK))))
      let timeDiff;
      timeDiff = Date.now() - lastActive;
      let minuteDiff = timeDiff / (1000 * 60)
      if(minuteDiff > 30)
      {
        let domain = localStorage.getEncryptedItem('domain')
        let accounts_url = localStorage.getEncryptedItem('ACCOUNTS_URL');
        localStorage.clear();
        window.location.href =  accounts_url+'auth/logout/' +encodeURIComponent(this.commonService.encrypt(currentURL))
      }else{
        this.reset();
      }
   
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
  }
  async ngOnInit() {
    $("body").addClass(localStorage.getEncryptedItem('domain').split(".")[0]);
    console.log(environment.STYLES[localStorage.getEncryptedItem('domain')]);
    // environment.STYLES[localStorage.getEncryptedItem('domain')].forEach(element => {
    //   document.documentElement.style.setProperty(Object.keys(element)[0], element[Object.keys(element)[0]]);
    //   // console.log('element',element);
      
    // });
  //  await this.setPermision();
  this.cookieConsent();
    this.reset();
    /* setTimeout(() => {
      let language = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].language: "de";
      this.emitService.emitLoginLanguageChange(language);
    },100); */
  // this.i18nService.setLanguage(v)
  // console.log(lang);
  // let lang =  this.commonService.getCookie(this.commonService.encrypt('languageCookie'));
  // console.log(lang);
  // console.log(this.commonService.decrypt(lang));
  // let defaultLang = this.commonService.decrypt(lang)
  // console.log('lang',defaultLang);
  this.versionCheckService.initVersionCheck('version.json');

  }
  getWhiteLabelConfig(domain: any) {
    let formData = new FormData();
    formData.append('domain', domain);
    let object = this.commonService.createObject(formData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken', encryptedToken);
    this.appService.getWhiteLabelConfig(encrData).subscribe(data => {
      if (data.status) {
        let config = data['data']['configdata'];
        localStorage.setEncryptedItem('configData',JSON.stringify(config));
        localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS',config['accounts_api_url']);
        localStorage.setEncryptedItem('API_END_POINT_ADMIN',config['admin_api_url']);
        localStorage.setEncryptedItem('API_END_POINT_GATE2B',config['gate2b_api_url']);
        this.commonService.setCookie(this.commonService.encrypt('API_END_POINT_ACCOUNTS'),this.commonService.encrypt(config['accounts_api_url']) , 10000, environment.cookieDomain, '/');
        this.commonService.setCookie(this.commonService.encrypt('API_END_POINT_ADMIN'),this.commonService.encrypt(config['admin_api_url']) , 10000, environment.cookieDomain, '/');
        this.commonService.setCookie(this.commonService.encrypt('API_END_POINT_GATE2B'),this.commonService.encrypt(config['gate2b_api_url']) , 10000, environment.cookieDomain, '/');
        this.commonService.setCookie(this.commonService.encrypt('ACCOUNTS_URL'),this.commonService.encrypt(config['accounts_url']) , 10000, environment.cookieDomain, '/');
        this.commonService.setCookie(this.commonService.encrypt('ADMIN_URL'),this.commonService.encrypt(config['admin_url']) , 10000, environment.cookieDomain, '/');
        this.commonService.setCookie(this.commonService.encrypt('GATE2B_URL'),this.commonService.encrypt(config['gate2b_url']) , 10000, environment.cookieDomain, '/');
        localStorage.setEncryptedItem('domain', config['domain']);
        localStorage.setEncryptedItem('ACCOUNTS_URL',config['accounts_url']);
        localStorage.setEncryptedItem('ADMIN_URL',config['admin_url']);
        localStorage.setEncryptedItem('GATE2B_URL',config['gate2b_url']);
        this.emitService.setWhiteLabelConfig(config);
        this.commonService.setWhiteLabelImageData(data['data']['imageData']);
        const currentTheme = $("body").hasClass("smart-style-1") ? 'dark_theme' : 'light_theme';
        setTimeout(() => {
          // result = FAVI[domain][0].title;
          // config['favi_title'] = 'iomarket AG' // only for local
          config['favi_title'] = config.company_name.replace(/ AG$/, '')+' Admin';
          document.getElementById('outputFaviTitle').setAttribute("innerHTML",config['favi_title']);
          document.getElementById('outputFaviTitle').innerHTML = config['favi_title'];
          // let imageData = this.commonService.getWhiteLabelImageData("favicon");
          console.log("data['data']['imageData']",data['data']['images']);
        });

        const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
        if (link) {
          link.href = data['data']['imageData']['favicon']['dataUrl'];
        } else {
          const newLink = document.createElement('link');
          newLink.rel = 'icon';
          newLink.href = data['data']['imageData']['favicon']['dataUrl'];
          document.getElementsByTagName('head')[0].appendChild(newLink);
        }

        let style = config['color'];
        for (const [cssVar, themeColors] of Object.entries(style)) {
          // Set the CSS variable for the current theme
          if (themeColors[currentTheme]) {
              document.documentElement.style.setProperty(cssVar, themeColors[currentTheme]);
              if(cssVar=='--theme-color-1') {
                const rgb = this.hexToRgb(themeColors[currentTheme]);
    
                const color = new Color(rgb[0], rgb[1], rgb[2]);
                const solver = new Solver(color);
                const result = solver.solve();
                let filter = 'brightness(0) saturate(100%)' + result.filter.split('filter:')[1];
                console.log(filter);
                
                document.documentElement.style.setProperty('--filter',filter.slice(0, -1));
              }
          }
        }

        const styleElement = document.createElement('style');
        styleElement.textContent = style.extraStyle;
        document.head.appendChild(styleElement);
      }
    })
  }
  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }
  cookieConsent(){
      if( this.commonService.getCookie(environment.cookieConsent) == 'false' ){
        this.showCookieConsent = false;
      
    }else{
      this.showCookieConsent = true;
      this.commonService.setCookie(environment.cookieConsent,"true",10000,environment.cookieDomain,'/');
    }
  }
  hideCookieConsent(delay =0){
    if(this.showCookieConsent){
      setTimeout(()=> { 
        $(".cookie-consent").fadeOut(1500);  
        this.showCookieConsent = false ; 
        this.commonService.setCookie(environment.cookieConsent,"false",10000,environment.cookieDomain,'/');     
      }, delay)
    }
  }
  hexToRgb(hex_color) {
    var pattern_color = "^#([A-Fa-f0-9]{6})$";
    if (hex_color.match(pattern_color)) {
        var hex_color = hex_color.replace("#", "")
            , r = parseInt(hex_color.substring(0, 2), 16)
            , g = parseInt(hex_color.substring(2, 4), 16)
            , b = parseInt(hex_color.substring(4, 6), 16);
        return [r,g,b];
    }
    else {
        console.log('Error Color Format');
    }
  }
}
