import { Injectable } from '@angular/core';
import * as jwt from "jsonwebtoken";
import * as crypto from 'crypto-browserify'
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

declare var Buffer: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  pvtkey;
  private localKeys = {
    "language": "language",
    "gender": "gender",
    "chatInfo": "chatInfo",
    "countries": "countries",
    "packages": "packages",
    "dateFormat": "dateFormat"
  };
  themeChangeSubject = new BehaviorSubject<boolean>(false);
  themeChangeEvent = this.themeChangeSubject.asObservable();
  companyDetailsSubject = new BehaviorSubject<boolean>(false);
  companyDetailsEvent = this.companyDetailsSubject.asObservable();
  billingOverviewSubject = new BehaviorSubject<boolean>(false);
  billingOverviewEvent = this.billingOverviewSubject.asObservable();
  public profilemImageChange = new BehaviorSubject<boolean>(false);
  profilemImageChangeObservable = this.profilemImageChange.asObservable();
  
  public profileDeatilsChange = new BehaviorSubject<boolean>(false);
  userDataChangeObservable = this.profileDeatilsChange.asObservable();

  public profileDataChange = new  BehaviorSubject<boolean>(false);
  profileDataChangeObservable = this.profileDataChange.asObservable();
  whiteLabelImage: any;
  constructor(private httpClient: HttpClient){}
  createObject(data) {
    var object = {}
    if (!Array.prototype.forEach) {
      Array.prototype.forEach = function (fn, scope) {
        for (var i = 0, len = this.length; i < len; ++i) {
          fn.call(scope, this[i], i, this);
        }
      }
    }
    data.forEach(function (value, key) {
      object[key] = value;
    });
    object['rolePartyId'] = localStorage.getEncryptedItem('partyID');
    object['roleAccId'] = localStorage.getEncryptedItem('accId');

    return object;
  }
  createToken(data) {
    let cert;
    let privateKey = environment.privateKey.key;
    let key = '';
    let begin = '-----BEGIN RSA PRIVATE KEY-----';
    let end = '-----END RSA PRIVATE KEY-----';
    while (privateKey.length > 0) {
      key += privateKey.substring(0, 64) + '\n';
      privateKey = privateKey.substring(64);
    }
    cert = `${begin}\n${key}${end}\n`;
    let paramsOption = { algorithm: 'RS256', expiresIn: 120 };
    let token = jwt.sign(data, cert, paramsOption);
    this.setCookie(this.encrypt(environment.USER_ACTIVE_CHECK),this.encrypt(new Date().toString()),10000,environment.cookieDomain,'/')
    
    return token;
  }
  encrypt(data) {

    var binaryEncryptionKey = new Buffer(environment.encryptionKey, "base64");
    var binaryIV = new Buffer(0);

    var cipher = crypto.createCipheriv("AES-128-ECB", binaryEncryptionKey, binaryIV);
    var encryptedInput = (
      cipher.update(data, "utf8", "base64") +
      cipher.final("base64")
    );
    return encryptedInput;
  }

  decrypt(data) {
    try {
      
   
      var binaryEncryptionKey = new Buffer( environment.encryptionKey, "base64" );
      var binaryIV = new Buffer( 0 );

      var decipher = crypto.createDecipheriv( "AES-128-ECB", binaryEncryptionKey, binaryIV );
      var decryptedInput = (
          decipher.update( data, "base64", "utf8" ) +
          decipher.final( "utf8" )
        );
      return decryptedInput;
    } catch (error) {

      return '';
    }   
  }
  getCurrentLanguageCode() {
    let currentLang = localStorage.getEncryptedItem("current_lang");
    let lang = "de";
    if (currentLang) {
        currentLang = JSON.parse(currentLang);
        lang = currentLang["key"];
    }
    return lang;
  }
  fetchLanguage(language) {
    let langData = this.getCommonDataOnLanuguage(language, this.localKeys.language);
    if (!langData || this.getCurrentLanguageCode() != language) {
        var formData = new FormData();
        formData.append('userId','');
        formData.append("loggedInTime", '');
        formData.append("lastRequestTime", '');            
        return this.httpClient.get("assets/api/languages.json").pipe(map(res => {
            this.setCommonDataOnLanguage(language, this.localKeys.language, res["DATA"]);
            return res["DATA"];
        }));
    } else {
        return new Observable((observable) => {
            observable.next(langData);
            observable.complete();
        });
    }
  }
  getCommonDataOnLanuguage(language, key) {
    let commonData = localStorage.getEncryptedItem("common-data");
    let retData = null;
    if (commonData) {
        try {
            commonData = JSON.parse(commonData);
            let commonLangData = commonData[language];
            if (commonLangData && commonLangData[key]) {
                retData = commonLangData[key];
            }
        }
        catch (e) {
            retData = null;
            throw "Error in parsing commondata from localStorage";
        }
        finally {
        }
    }
    return retData;
  }
  setCommonDataOnLanguage(language, key, data) {
    let commonData: any = localStorage.getEncryptedItem("common-data");
    let langData;
    if (commonData) {
        commonData = JSON.parse(commonData);
        commonData[language] = commonData[language] || {};
        langData = commonData[language];
    } else {
        commonData = {};
        commonData[language] = {};
        langData = commonData[language];
    }
    langData[key] = data;
    localStorage.setEncryptedItem("common-data", JSON.stringify(commonData));
}
  
getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
setCookie(name: string, value: any, expireDays: number,domain:string ,path: string = '') {
  let d:Date = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  let expires:string = `expires=${d.toUTCString()}`;
  let cpath:string = path ? `; path=${path}` : '';
  document.cookie = `${name}=${value};domain=${localStorage.getEncryptedItem('domain')}; ${expires}${cpath};`;
}
setWhiteLabelImageData(data){
  this.whiteLabelImage = data;
}
getWhiteLabelImageData(image_type){ 
  return this.whiteLabelImage[image_type];
}
}
