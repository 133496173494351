<ul class="header-dropdown-list ng-cloak" style="margin-top: -6px !important;" *ngIf="reload" (click)="showList($event)">
    <li class="dropdown" style="width: 114px;
    height: 49px;
    padding: 12px;">
        <a class="dropdown-toggle">
            <img src="assets/img/flags/{{currentLanguage.key}}.svg" class="flag" alt="{{currentLanguage.alt}}" />
            <span style="font-size: 14px;" class="theme-header-text-color language-text">&nbsp;{{ currentLanguage.title | i18n}}&nbsp;</span>

            <!-- <i class="fa fa-angle-down"></i> -->
        </a>
        <ul class="dropdown-menu mt-2 language-list" #languageList>
            <li [class.active]="language == currentLanguage" *ngFor="let language of languages">
                <a (click)="setLanguage(language)" class="dropdown-item pl-2 mt-1"><img src="assets/img/flags/{{language.key}}.svg" class="flag" alt="{{language.alt}}" /> {{ language.title | i18n}}</a>
            </li>
        </ul>
    </li>
</ul>