import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Subject, BehaviorSubject } from 'rxjs';

export type InternalStateType = {
  [key: string]: any
};
@Injectable({
  providedIn: 'root'
})
export class AppService {
  _state: InternalStateType = {};
  public subject = new Subject<any>();
  style;
  countryList:any = [];
  private _navigationNotify = new BehaviorSubject<string>('');
  readonly _navigationNotify$ = this._navigationNotify.asObservable();
  companyId = '';
  constructor(private httpClient: HttpClient) { }

  setLocalStorageFunctions() {
    Storage.prototype.getEncryptedItem = function (item) {
      if (item) {
        try{
          let encodedKey = btoa(item);
          let itemValue = localStorage.getItem(encodedKey);
          if (itemValue) {
            return atob(itemValue);
          }
        }
        catch {
          let encodedKey = toBase64(item);
          let itemValue = localStorage.getItem(encodedKey);
          if (itemValue) {
            return fromBase64(itemValue);
          }
        }
      }
      return null;
    }
    Storage.prototype.setEncryptedItem = function (item, itemValue) {
      if (item && itemValue) {
        try {
          let encodedKey = btoa(item);
          let encodedValue = btoa(itemValue);
          localStorage.setItem(encodedKey, encodedValue);
          return true;
        }
        catch {
          let encodedKey = toBase64(item);
          let encodedValue = toBase64(itemValue);
          localStorage.setItem(encodedKey, encodedValue);
          return true;
        }
      }
      return false;
    }
    Storage.prototype.removeEncryptedItem = function (item) {
      if (item) {
        try {
          let encodedKey = btoa(item);
          localStorage.removeItem(encodedKey);
          return true;
        }
        catch {
          let encodedKey = toBase64(item);
          localStorage.removeItem(encodedKey);
          return true;
        }
      }
      return false;
    }
  }

  createAccessToken() {
    return this.httpClient.post(localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS') + 'users/createAccessToken', {}).pipe(map((response: Response) => response))
  }
  sendMessage(message: string) {
    // this.subject.next({ style: message });
    this.style = { style: message }
  }

  clearMessages() {
    this.subject.next();
  }

  notify(data) {
    this._navigationNotify.next(data);
  }
  getWhiteLabelConfig(data){
    return  this.httpClient.post(localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS')+'auth/getWhiteLabelConfig',data).pipe(map((response:Response)=>response))
  }
  // getCountryList(){
  //   return this.httpClient.get(environment.API_END_POINT_ACCOUNT+`user/countryCode`);
  // }

}
function toBase64(str) {
  // Encode the string as a Uint8Array
  let utf8Bytes = new TextEncoder().encode(str);
  // Create a binary string from the Uint8Array
  let binaryString = Array.from(utf8Bytes, byte => String.fromCharCode(byte)).join('');
  // Encode the binary string in Base64
  return btoa(binaryString);
}

function fromBase64(base64) {
  // Decode the Base64 string
  let binaryString = atob(base64);
  // Convert binary string to a Uint8Array
  let bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  // Decode the Uint8Array to a UTF-8 string
  return new TextDecoder().decode(bytes);
}