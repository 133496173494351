import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import { NumberPipe } from './number.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FieldFilterPipe, MomentPipe, NumberPipe],
  exports: [FieldFilterPipe, MomentPipe, NumberPipe]
})
export class PipesModule { }
