import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";


const defaultUser = {
  username: "Guest"
}
@Injectable()
export class UserService {
  // public user$ = new BehaviorSubject({...defaultUser});
  httpOptions = {
    headers: new HttpHeaders(),
    withCredentials: true
  };
  API_END_POINT_ACCOUNTS: any;
  constructor(private httpClient: HttpClient) {
    // this.API_END_POINT_ACCOUNTS = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    // this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$)
  }

  // public logout(){
  //   this.user$.next({...defaultUser})
  // }
  // // logoutUsers$() {
  //   return this.httpClient.post(environment.API_END_POINT_ACCOUNT + 'users/logout', '', this.httpOptions).pipe(map((response: Response) => response));

  // }

  userData(data){
    return this.httpClient.post<any>(localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS')+'users/user-profile',data);
  }
  getCommissionAgentConfig(data){
    return  this.httpClient.post(localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS')+'users/getCommissionAgentConfig',data).pipe(map((response:Response)=>response))
  }
  getCompanyLogoImages(data){
    return this.httpClient.post(localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS')+'auth/getCompanyLogoImages',data).pipe(map((response:Response)=>response));
  }
}
