import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";

const routes: Routes = [
  {
		path: "", redirectTo: 'companyDetails', pathMatch: 'full',
	},
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle:"" },
    children: [
      {
        path: 'companyDetails',
         loadChildren: () => import('./features/company-details/company-details.module').then(m => m.CompanyDetailsModule),
         data: {pageTitle: 'routerCompanyProfile', right: 'admin.companyProfile.' },
         canActivate: [AuthGuard],
      },
      {
        path: 'billingOverview',
        loadChildren: () => import('./features/billing-overview/billing-overview.module').then(m => m.BillingOverviewModule),
        data: {pageTitle: 'common_billing_overView', right: 'admin.billing.' },
        canActivate: [AuthGuard],
      },
      {
        path: "userManagement",
        loadChildren: () => import('./features/user-management/user-management.module').then(m => m.UserManagementModule),
        data: { pageTitle: "subscription_plans_and_pricing", right: 'admin.user.' },
        canActivate: [AuthGuard],
      },
      {
        path: "manageSubscription",
        loadChildren: () => import('./features/manage-subscriptions/manage-subscriptions.module').then(m => m.ManageSubscriptionsModule),
        data: { pageTitle: "subscription_plans_and_pricing", right: 'admin.subscription.' },
        canActivate: [AuthGuard],
      },
      {
        path: "manageSubscription/:package",
        loadChildren: () => import('./features/manage-subscriptions/manage-subscriptions.module').then(m => m.ManageSubscriptionsModule),
        data: { pageTitle: "subscription_plans_and_pricing", right: 'admin.subscription.' },
        canActivate: [AuthGuard],
      },
      {
        path: "plan",
        loadChildren: () => import('./features/subscriptions-new/subscriptions-new.module').then(m => m.SubscriptionsNewModule),
        data: { pageTitle: "subscription_plans_and_pricing", right: 'admin.subscription.' },
        canActivate: [AuthGuard],
      },
      {
        path: "plan/:type",
        loadChildren: () => import('./features/subscriptions-new/subscriptions-new.module').then(m => m.SubscriptionsNewModule),
        data: { pageTitle: "subscription_plans_and_pricing", right: 'admin.subscription.' },
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'companyDelete/:id',
      //    loadChildren: () => import('./features/company-delete/company-delete.module').then(m => m.CompanyDeleteModule),
      //    data: {pageTitle: 'routerCompanyProfile', right: 'admin.companyProfile.' },
      //    canActivate: [AuthGuard],
      // },
      {
        path: "contracts",
        loadChildren: () => import('./features/contracts/contracts.module').then(m => m.ContractsModule),
        data: { pageTitle: "common_contracts",right: 'admin.user.' },
        canActivate: [AuthGuard],
      },
      { path: "**", redirectTo: "companyDetails"}
    ]
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
