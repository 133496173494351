

export const languages: any = [
  {
    "key": "en",
    "alt": "English",
    "title": "English"
  },
  {
    "key": "fr",
    "alt": "France",
    "title": "Français"
  },
  {
    "key": "de",
    "alt": "German",
    "title": "Deutsch"
  }
];
