import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html',
  styleUrls:['footer.component.scss']
})
export class FooterComponent implements OnInit {
  accountsUrl = localStorage.getEncryptedItem('ACCOUNTS_URL');
  year = new Date().getFullYear();
  companyName: any;
  constructor() {}

  ngOnInit() {
    let domain = localStorage.getEncryptedItem('domain');
    let configData = localStorage.getEncryptedItem('configData');
    let config = JSON.parse(configData);
    this.companyName = config.company_name.replace(/ AG$/, '');
  }

}
